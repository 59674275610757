<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!-- bio -->
        <b-col cols="12">
          <b-form-group :label="$t('bio')" label-for="bio">
            <b-form-textarea
              id="bio-area"
              v-model="options.participant.bio"
              rows="4"
              :placeholder="$t('bio')"
            />
          </b-form-group>
        </b-col>
        <!--/ bio -->

        <!-- Country -->
        <b-col md="6">
          <b-form-group label-for="countryList" :label="$t('Country')">
            <v-select
              id="countryList"
              v-model="options.participant.country"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="countryOptions"
            />
          </b-form-group>
        </b-col>
        <!--/ Country -->

        <b-col sm="6">
          <b-form-group :label="$t('Address')" label-for="account-address">
            <b-form-input
              v-model="options.participant.address"
              name="address"
              :placeholder="$t('Address')"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group :label="$t('city')" label-for="account-city">
            <b-form-input
              v-model="options.participant.city"
              name="city"
              :placeholder="$t('City')"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
            :label="$t('postal code')"
            label-for="account-postalCode"
          >
            <b-form-input
              v-model="options.participant.postalCode"
              name="postalCode"
              :placeholder="$t('postal code')"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click.prevent="submitForm"
          >
            {{ $t('Save Changes') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1"
            variant="outline-secondary"
            @click.prevent="reloadData"
          >
            {{ $t('Reset') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      options: JSON.parse(JSON.stringify(this.userData)),
      countryOptions: [],
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
    }
  },
  mounted() {
    this.options = JSON.parse(JSON.stringify(this.userData))

    this.$store.dispatch('app-common/fetchCountries').then(response => {
      this.countryOptions = response.data
    })
  },
  methods: {
    reloadData() {
      this.options = JSON.parse(JSON.stringify(this.userData))
    },

    submitForm() {
      this.$store
        .dispatch('app-common/updateUserData', {
          ...this.options,
        })
        .then(() => {
          this.$emit('update')

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Entity Saved'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('Something went wrong!'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
